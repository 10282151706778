
<template>
  <div class="page">
    <Tables ref="xTable" :tableName="tableName" :tableData="tableData" :totalPage="totalPage" :isLoading="isLoading" :searchFormData="searchFormData" :showRefresh="true" :showSearch="true" @getList="getList"
      @clearSearch="clearSearch" @addItemInTable="addOne">
      <!--搜索自定义内容-->
      <el-form-item slot="search-item">
        <el-input v-model="searchFormData.cardno" size="small" clearable placeholder="按卡号查询"></el-input>
      </el-form-item>
      <el-form-item slot="search-item">
        <el-select v-model="searchFormData.model" placeholder="按卡模式查询" clearable size="small">
          <el-option label="在线卡" :value="10"> </el-option>
          <el-option label="离线卡" :value="20"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item slot="search-item">
        <el-select v-model="searchFormData.type" placeholder="按卡类型查询" clearable size="small">
          <el-option label="金额卡" :value="10"> </el-option>
          <el-option label="次卡" :value="20"> </el-option>
          <el-option label="包年包月" :value="30"> </el-option>
        </el-select>
      </el-form-item>

      <!--表格内容-->
      <vxe-table-column slot="table-item" field="cardno" title="卡号" align="center" min-width="100px" />
      <vxe-table-column slot="table-item" field="is_abnormal" title="卡状态" align="center" min-width="120px">
        <template v-slot="{ row }">
          <span>{{ types.card_status[row.card_status] || '未知状态' }}</span>
          <br>
          <span v-if="row.report_lose_datetime != null">将于{{row.report_lose_datetime}}挂失</span>
        </template>
      </vxe-table-column>
      <vxe-table-column slot="table-item" field="is_abnormal" title="异常原因" align="center" min-width="120px">
        <template v-slot="{ row }">
          <span :class="row.is_abnormal==2? 'warn-color':''">{{ types.abnormal_status[row.is_abnormal] || '未知状态' }}</span>
          <br>
          <span v-if="row.report_lose_datetime != null">将于{{row.report_lose_datetime}}挂失</span>
        </template>
      </vxe-table-column>
      <vxe-table-column slot="table-item" field="model" title="卡模式" align="center" min-width="80px">
        <template v-slot="{ row }">
          <span>{{ types.model[row.model] || '未知卡' }}</span>
        </template>
      </vxe-table-column>
      <vxe-table-column slot="table-item" field="type" title="卡类型" align="center" min-width="80px">
        <template v-slot="{ row }">
          <span>{{ types.type[row.type] || '未知类型' }}</span>
        </template>
      </vxe-table-column>
      <vxe-table-column slot="table-item" field="balance" title="剩余" align="center" min-width="80px">
        <template v-slot="{ row }">
          <span>{{ row.balance }}{{ types.btype[row.type] || '' }}</span>
        </template>
      </vxe-table-column>
      <vxe-table-column slot="table-item" field="create_time" title="创建时间" align="center" min-width="100px" />
      <vxe-table-column slot="table-item" field="fans_id" title="绑定用户" align="center" min-width="100px">
        <template v-slot="{ row }">
          <span>{{ row.nickname || '无' }}</span>
        </template>
      </vxe-table-column>
      <vxe-table-column slot="table-item" title="操作" align="center" width="160">
        <template v-slot="{ row }">
          <el-button size="small" plain @click="detailOne(row)" v-if="$hasAccess('user.cards/view')">详情</el-button>
          <el-button size="small" plain @click="reportLoss(row)" v-if="$hasAccess('user.cards/add') && row.card_status == 1 && row.report_lose_datetime == null">挂失</el-button>
          <el-button size="small" plain @click="recoveryCard(row)" v-if="($hasAccess('user.cards/add') && row.card_status == 0) || row.report_lose_datetime !=null" style="background:red; color:white">恢复</el-button>
        </template>
      </vxe-table-column>
    </Tables>

    <!-- 详情 -->
    <el-dialog title="电卡详情" :visible.sync="detailDialogShow" :close-on-click-modal="false" :close-on-press-escape="false" width="800px">
      <el-form ref="detailForm" label-width="140px" class="form" size="mini">
        <el-row>
          <el-col :xs="{ span: 24, offset: 0 }" :sm="{ span: 10, offset: 1 }">
            <!-- 电卡信息 -->
            <div class="el-block">
              <div class="row-title">电卡信息</div>
              <el-form-item label="卡号：">{{ detailDialogFormData.cardno }}</el-form-item>
              <!-- <el-form-item label="卡密：">{{detailDialogFormData.pwd||'无'}}</el-form-item> -->
              <el-form-item label="卡模式：">{{ types.model[detailDialogFormData.model] || '未知' }}</el-form-item>
              <el-form-item label="卡类型：">{{ types.type[detailDialogFormData.type] || '未知类型' }}</el-form-item>
              <el-form-item label="卡剩余：">{{ detailDialogFormData.balance }}{{ types.btype[detailDialogFormData.type] || '' }}</el-form-item>
              <el-form-item label="创建时间：">{{ detailDialogFormData.create_time }}</el-form-item>
            </div>
          </el-col>
          <el-col :xs="{ span: 24, offset: 0 }" :sm="{ span: 10, offset: 2 }">
            <!-- 绑定信息 -->
            <div class="el-block">
              <div class="row-title">绑定信息</div>
              <el-form-item label="绑定用户：">{{ detailDialogFormData.nickname || '无' }}</el-form-item>
              <el-form-item label="绑定时间：">{{ detailDialogFormData.band_time || '无' }}</el-form-item>
            </div>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消</el-button>
      </div>
    </el-dialog>

    <!-- 定时挂失 -->
    <el-dialog title="定时挂失" :visible.sync="reportLossDialogShow" :close-on-click-modal="false" :close-on-press-escape="false" top="40vh" width="500px">
      <div class="report-loss-line">
        <p> 挂失类型：</p>
        <el-select v-model="reportLossFormData.type" placeholder="挂失类型" clearable size="small">
          <el-option label="即时" :value="0"> </el-option>
          <el-option label="定时" :value="1"> </el-option>
        </el-select>
      </div>
      <div class="report-loss-line" v-if="reportLossFormData.type == 1">
        <p> 选择日期时间：</p>
        <el-date-picker v-model="reportLossFormData.report_lose_datetime" type="datetime" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" placeholder="请选择日期和时间"></el-date-picker>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" size="small" plain icon="el-icon-check" @click="reportLoseCommit()" v-if="$hasAccess('user.cards/add')">提 交 挂 失</el-button>
        <el-button size="small" plain icon="el-icon-close" @click="closeReportLose()">取 消</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import Tables from "@/components/tables";
export default {
  name: "cards-exception-list",
  components: {
    Tables,
  },
  data() {
    return {
      // 表格信息
      tableName: "电卡列表",
      isLoading: false,
      tableData: [],
      totalPage: 0,
      searchFormData: {},
      types: {
        model: {
          10: "在线卡",
          20: "离线卡",
        },
        type: {
          10: "金额卡",
          20: "次卡",
          30: "包年包月",
        },
        btype: {
          10: "元",
          20: "次",
          30: "",
        },
        card_status: {
          0: "挂失",
          1: "正常",
        },
        abnormal_status: {
          0: "正常",
          1: "卡号异常",
          2: "金额异常",
        },
      },
      // 详情
      detailDialogShow: false,
      detailDialogFormData: {},
      // 新增
      addDialogShow: false,
      addDialogFormData: {},
      // 挂失
      reportLossDialogShow: false,
      reportLossFormData: {
        type: 0,
      },
      // 规则
      rules: {
        cardno: [{ required: true, message: "请输入卡号", trigger: "change" }],
      },
    };
  },
  methods: {
    // 表格列表
    async getList(currentPage, pageSize) {
      const params = {
        token: this.$store.state.user.token,
        page: currentPage,
        size: pageSize,
      };
      this.isLoading = true;
      const searchData = this.searchFormData;
      let data = Object.assign(params, searchData);
      console.log("data", data);
      const res = await this.$api.Cards.GetExceptionCardsList(data);
      this.tableData = res.data;
      this.totalPage = res.total;
      this.isLoading = false;
    },
    // 重置搜索
    clearSearch() {
      this.searchFormData = {};
    },
    // 重置form
    resetFormData() {
      this.addDialogFormData = {
        cardno: "",
        model: 10,
      };
    },
    // 关闭弹窗
    closeDialog() {
      this.detailDialogShow = false;
      this.addDialogShow = false;
    },
    // 详情
    async detailOne(row) {
      const params = {
        token: this.$store.state.user.token,
        card_id: row.card_id,
      };
      const res = await this.$api.Cards.GetCardsDetail(params);
      this.detailDialogFormData = res;
      this.detailDialogShow = true;
    },
    // 新增
    addOne() {
      this.resetFormData();
      this.addDialogShow = true;
    },
    // 新增保存
    saveAdd() {
      this.$refs.addForm.validate(async (valid) => {
        if (valid) {
          const params = {
            token: this.$store.state.user.token,
            cardno: this.addDialogFormData.cardno,
            model: this.addDialogFormData.model,
          };
          await this.$api.Cards.AddCard(params);
          this.$notify({
            title: "成功",
            message: "新增成功",
            type: "success",
          });
          this.closeDialog();
          this.$refs.xTable.refreshTable();
        }
      });
    },
    // 挂失||取消挂失
    recoveryCard(row) {
      this.$confirm("确定要将卡号" + row.cardno + "取消挂失吗", "提示", {
        cancelButtonClass: "btn-custom-cancel",
        confirmButtonText: "取消挂失",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        const params = {
          token: this.$store.state.user.token,
          cardno: row.cardno,
        };
        this.$api.Cards.recoveryCard(params)
          .then((res) => {
            this.$notify({
              title: "成功",
              message: "解除挂失成功",
              type: "success",
            });
            // 刷新
            this.$refs.xTable.refreshTable();
          })
          .catch((err) => {
            this.closeReportLose();
            if (err.indexOf("成功") > -1) {
              this.$notify({
                title: "成功",
                message: "解除挂失成功",
                type: "success",
              });
              // 刷新
              this.$refs.xTable.refreshTable();
            } else {
              this.$notify({
                title: "失败",
                message: "解除挂失失败",
                type: "error",
              });
            }
          });
      });
    },

    // 挂失
    reportLoss: function (row) {
      this.reportLossDialogShow = true;
      this.reportLossFormData.row = row;
    },

    reportLoseCommit: function () {
      let row = this.reportLossFormData.row;
      this.$confirm("确定要将卡号" + row.cardno + "挂失吗", "提示", {
        cancelButtonClass: "btn-custom-cancel",
        confirmButtonText: "挂失",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        const params = {
          token: this.$store.state.user.token,
          cardno: row.cardno,
          type: this.reportLossFormData.type,
          report_lose_datetime: this.reportLossFormData.report_lose_datetime,
        };
        console.log(params);
        this.$api.Cards.ShellCard(params)
          .then((res) => {
            this.$notify({
              title: "成功",
              message: "挂失成功",
              type: "success",
            });
            // 刷新
            this.closeReportLose();
            this.$refs.xTable.refreshTable();
          })
          .catch((err) => {
            this.closeReportLose();
            if (err.indexOf("成功") > -1) {
              this.$notify({
                title: "成功",
                message: "挂失成功",
                type: "success",
              });
              // 刷新
              this.$refs.xTable.refreshTable();
            } else {
              this.$notify({
                title: "失败",
                message: "挂失失败",
                type: "error",
              });
            }
          });
      });
    },
    closeReportLose: function () {
      this.reportLossDialogShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.row-title {
  font-size: 14px;
  line-height: 20px;
  padding: 4px 0;
  margin-bottom: 18px;

  &:before {
    content: " ";
    width: 5px;
    height: 20px;
    background: #008e4d;
    display: block;
    float: left;
    margin: 0 10px 0 0;
  }
}
.avatar {
  width: 24px;
  height: 24px;
  display: block;
  margin: 0 auto;
}
.avatarD {
  width: 24px;
  height: 24px;
  display: block;
}

.report-loss-line {
  p {
    width: 100px;
    text-align: right;
  }
  display: flex;
  align-items: center;
}
</style>
